/* You can add global styles to this file, and also import other style files */
@import './assets/fontawesome/css/all.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '~swiper/swiper-bundle';

:root {
  --primary-color: #dc0d15;
  --secondary-color: #d8d8d8;
  --terziary-color: #393e42;
  --quaternary-color: #52565d;
  --neutral-color: #ffffff;

  --fa-primary-color: #6c8694;
  --fa-secondary-color: #52565d;
  --fa-primary-opacity: #6c8694;
  --fa-secondary-opacity: #52565d;
}
//LOGIN STYLE
nz-header {
  background: var(--secondary-color) !important;
  color: var(--terziary-color) !important;
  font-size: 20px;
  height: 70px !important;
  .uiHeaderRow {
    height: 100%;
    .uiHeaderLeftCol {
      height: 100%;
    }
    .uiHeaderRightCol {
      height: 100%;
      text-align: right;
      .uiHeaderHomeBtn {
        margin-right: 0.5rem;
        font-size: 16px;
      }
      .uiHeaderHomeBtn:before {
        color: var(--fa-secondary-color);
      }
      .uiLogoutSpan {
        font-size: 16px;
        vertical-align: middle;
      }
      .uiLogoutBtn {
        font-size: 20px;
        margin-left: 1.5rem;
        cursor: pointer;
      }
      .uiLogoutBtn:hover,
      .uiLogoutBtn:active {
        color: var(--primary-color);
      }
    }
  }
}
.uiAdminHeader {
  padding: 0 24px;
  .uiHeaderAdminRow {
    height: 100%;
    .uiHeaderAdminLeftCol {
      height: 100%;
      .uiUncollapsedLogo {
        margin-right: 2rem;
        width: 200px;
        display: inline-block;
        vertical-align: middle;
      }
      .uiCollapsedLogo {
        width: 30px;
        display: inline-block;
        vertical-align: middle;
      }
      .uiSidebarTrigger {
        color: var(--terziary-color) !important;
        cursor: pointer;
        font-size: 20px;
        width: 100%;
        margin: 0 2rem;
      }
      .uiSidebarTrigger:hover {
        color: var(--primary-color) !important;
      }
    }
    .uiHeaderCenterCol {
      text-align: center;
    }

    .uiHeaderAdminRightCol {
      .uiHomeBtn {
        padding: 0;
        box-shadow: none;
        color: var(--terziary-color);
        background-color: var(--secondary-color);
        border: none;
        margin-right: 1rem;
      }
      .uiHomeBtn:hover,
      .uiHomeBtn:active {
        border: none;
        color: var(--primary-color);
      }
      .uiHomeBtn:disabled {
        background-color: #eeeeee;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
      }
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      .uiHeaderHomeBtn {
        margin-right: 0.5rem;
        font-size: 16px;
      }
      .uiHeaderHomeBtn:before {
        color: #777;
      }
      .uiHeaderHomeBtn:after {
        color: #777;
      }
      .uiLogoutSpan {
        font-size: 16px;
        vertical-align: middle;
      }
      .uiLogoutBtn {
        font-size: 20px;
        margin-left: 1.5rem;
        cursor: pointer;
      }
      .uiLogoutBtn:hover,
      .uiLogoutBtn:active {
        color: var(--primary-color);
      }
    }
  }
}
nz-content {
  background: var(--neutral-color);
}

nz-footer {
  height: 70px !important;
}

.uiAddForm {
  nz-select {
    width: 100%;
  }
  input,
  nz-select {
    margin-bottom: 1rem;
  }
}
.uiModalBackBtn {
  float: left;
  background-color: var(--secondary-color);
  border: 1px solid var(--terziary-color);
  color: var(--terziary-color);
  font-size: 16px;
  padding: 0 2rem;
}

.uiModalBackBtn:hover,
.uiModalBackBtn:active {
  border: 1px solid var(--primary-color);
  background-color: var(--neutral-color);
  color: var(--primary-color);
}

.uiModalAddBtn {
  background-color: var(--primary-color);
  border: 1px solid var(--terziary-color);
  color: var(--neutral-color);
  font-size: 16px;
  padding: 0 2rem;
}

.uiModalAddBtn:hover,
.uiModalAddBtn:active,
.uiModalAddBtn:focus {
  background-color: var(--neutral-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.uiLoginHeader {
  padding: 0 24px;
  .uiHeaderLoginRow {
    height: 100%;
    text-align: center;
    .uiUncollapsedLogo {
      width: 200px;
      height: auto;
    }
  }
}

.uiLoginContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .uiFormContainer {
    width: 80%;
    max-width: 500px;
    .uiLoginTitle {
      margin-top: 0.5em;
      font-size: 3rem;
    }
    img {
      margin-top: 4rem;
      margin-bottom: 2rem;
    }
    .uiDescriptionText {
      margin-bottom: 2rem;
    }
    input {
      width: 100%;
      margin-bottom: 1rem;
    }
    .loginBtn {
      margin-top: 1rem;
      width: 100%;
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);
      padding: 0 2rem;
      color: var(--neutral-color);
    }
    .loginBtn:hover,
    .loginBtn:active {
      background-color: var(--neutral-color);
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
    .errorMessage {
      color: var(--primary-color);
      margin-top: 2rem;
    }
  }
}

.uiHomeContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .uiHomeInner {
    width: 50%;
    margin-top: 2rem;
    .uiWelcome {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
    .uiHomeBtnContainer {
      text-align: center;
      .uiHomeBtn {
        padding: 0;
        margin-top: 2rem;
        width: 100%;
        box-shadow: none;
        color: var(--terziary-color);
        background-color: var(--secondary-color);
        border: 1px solid var(--terziary-color);
        .uiAdminIcon {
          margin-right: 1rem;
        }
      }
      .uiHomeBtn:hover,
      .uiHomeBtn:active {
        background-color: var(--neutral-color);
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
      }
      .uiHomeBtn:disabled {
        background-color: #eeeeee;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
      }
    }
  }
}
.uiPageLayout {
  .uiQuoteContainer {
    padding: 20px 20px 200px 20px !important;
    .uiVersionContainer {
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .uiVersionSelect {
        width: 65px;
        text-align: center;
        margin: 0 1rem;
      }
      .uiYellowBkgBtn {
        background-color: rgba(249, 255, 181, 0.8);
        border: 1px solid #cccccc;
        color: #000000d9;
      }
      .upload-cover {
        margin-left: 1rem;
        margin-right: 2rem;
      }
      .uiAuthorInput {
        margin-left: 1rem;
        width: 20%;
      }
    }

    .uiQuoteHead {
      width: 100%;
      background-color: #eeeeee;
      border: 1px solid black;
      padding: 10px;
      display: flex;
      .uiQuoteHeadForm {
        width: 95%;
        .uiHeadRow {
          width: 100%;
          margin: 0 !important;
          .uiHeadCol {
            padding: 0 !important;
            .uiInnerHeadCol {
              padding: 0 5px;
              span {
                font-weight: bold;
                font-size: 10px;
              }
              .uiGroupOtion {
                span {
                  font-size: 14px;
                  font-weight: normal;
                }
              }
              .uiEditSelect {
                width: 100%;
              }
              .uiDisabledBtn,
              .uiDisabledBtn[disabled] {
                background-color: #dddddd;
                border: 1px solid #cccccc;
                color: #000000d9;
              }
              .uiYellowBkgBtn {
                background-color: rgba(249, 255, 181, 0.8);
                border: 1px solid #cccccc;
                color: #000000d9;
              }
              .uiYellowBkgSelect > nz-select-top-control {
                background-color: rgba(249, 255, 181, 0.8);
                border: 1px solid #cccccc;
                color: #000000d9;
              }
            }
          }
        }
      }
      .uiQuoteHeadBtns {
        width: 4%;
        margin-left: 1%;
        border-left: 1px solid #cccccc;
        .uiHeadButton {
          height: 50%;
          width: 100%;
          background-color: transparent;
          border: none;
          .uiQuoteBtn {
            font-size: 24px;
          }
        }
        nz-divider {
          margin: 0;
        }
      }
    }

    .uiQuoteGroup {
      width: 100%;
      background-color: #eeeeee;
      border: 1px solid black;
      padding: 10px;
      display: flex;
      .uiQuoteGroupForm {
        width: 95%;
        .uiGroupHeadRow {
          margin-bottom: 10px !important;
          .uiGroupColorCol {
            text-align: center;
          }
        }
        .uiGroupRow {
          width: 100%;
          .uiGroupCol {
            padding: 0 !important;
            .uiInnerGroupCol {
              padding: 0 25px;
              span {
                font-weight: bold;
                font-size: 10px;
              }
              .uiGroupOtion {
                span {
                  font-size: 14px;
                  font-weight: normal;
                }
              }

              .uiEditSelect {
                width: 100%;
              }
              .uiDisabledBtn,
              .uiDisabledBtn[disabled] {
                background-color: #dddddd;
                border: 1px solid #cccccc;
                color: #000000d9;
              }
              .uiYellowBkgBtn {
                background-color: rgba(249, 255, 181, 0.8);
                border: 1px solid #cccccc;
                color: #000000d9;
              }
              .uiYellowBkgSelect > nz-select-top-control {
                background-color: rgba(249, 255, 181, 0.8);
                border: 1px solid #cccccc;
                color: #000000d9;
              }
            }
          }
          .uiGroupBodyCol {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .uiGroupBodyOptions {
            display: flex;
            align-items: center;
            .uiDisabledLabelText {
              color: rgba(0, 0, 0, 0.85);
            }
          }
          .uiGroupTotal {
            font-weight: 600;
            font-size: 15px !important;
          }
        }
        .uiGroupBodyRow {
          margin-bottom: 10px !important;
        }
      }
      .uiQuoteGroupsBtns {
        width: 4%;
        margin-left: 1%;
        border-left: 1px solid #cccccc;
        .uiGroupButton {
          height: 100%;
          width: 100%;
          background-color: transparent;
          border: none;
          .uiQuoteBtn {
            font-size: 24px;
          }
        }
        .uiGroupSaveButton {
          height: 50%;
          width: 100%;
          background-color: transparent;
          border: none;
          .uiQuoteBtn {
            font-size: 24px;
          }
        }
        nz-divider {
          margin: 0;
        }
      }
    }

    .uiQuoteCondition {
      width: 100%;
      background-color: #eeeeee;
      border: 1px solid black;
      padding: 10px;
      display: flex;
      .uiQuoteConditionForm {
        width: 95%;
        .uiConditionHeadRow {
          margin-bottom: 10px !important;
        }
        .uiConditionRow {
          width: 100%;
          .uiConditionCol {
            padding: 0 !important;
            .uiInnerConditionCol {
              padding: 0 25px;

              nz-select {
                width: 100%;
              }
              span {
                font-weight: bold;
                font-size: 10px;
              }

              .uiEditSelect {
                width: 100%;
              }
              .uiDisabledBtn,
              .uiDisabledBtn[disabled] {
                background-color: #dddddd;
                border: 1px solid #cccccc;
                color: #000000d9;
              }
              .uiYellowBkgBtn {
                background-color: rgba(249, 255, 181, 0.8);
                border: 1px solid #cccccc;
                color: #000000d9;
              }
              .uiYellowBkgSelect > nz-select-top-control {
                background-color: rgba(249, 255, 181, 0.8);
                border: 1px solid #cccccc;
                color: #000000d9;
              }
            }
          }
          .uiConditionBodyCol {
            display: flex;
            align-items: center;
          }
        }
        .uiConditionBodyRow {
          margin-bottom: 10px !important;
        }
      }
      .uiQuoteGroupsBtns {
        width: 4%;
        margin-left: 1%;
        border-left: 1px solid #cccccc;
        .uiGroupButton {
          height: 100%;
          width: 100%;
          background-color: transparent;
          border: none;
          .uiQuoteBtn {
            font-size: 24px;
          }
        }
        .uiGroupSaveButton {
          height: 50%;
          width: 100%;
          background-color: transparent;
          border: none;
          .uiQuoteBtn {
            font-size: 24px;
          }
        }
        nz-divider {
          margin: 0;
        }
      }
    }

    .uiQuoteClauses {
      width: 100%;
      background-color: #eeeeee;
      border: 1px solid black;
      padding: 10px;
      display: flex;
      .uiQuoteClausesForm {
        width: 95%;
        .uiClausesRow {
          width: 100%;
          .uiClausesCol {
            display: flex;
            align-items: center;
            quill-editor {
              width: 100%;
              background-color: #fff;
            }
            .uiCluauseTextarea p,
            .uiCluauseTextarea ul {
              margin-top: 0;
              margin-bottom: 1em;
            }
            .uiClausesInner {
              height: 400px;
              overflow-x: auto;
            }
          }
        }
        .uiClausesBodyRow {
          margin-bottom: 10px !important;
        }
      }
      .uiQuoteGroupsBtns {
        width: 4%;
        margin-left: 1%;
        border-left: 1px solid #cccccc;
        .uiGroupButton {
          height: 100%;
          width: 100%;
          background-color: transparent;
          border: none;
          .uiQuoteBtn {
            font-size: 24px;
          }
        }
        .uiGroupSaveButton {
          height: 50%;
          width: 100%;
          background-color: transparent;
          border: none;
          .uiQuoteBtn {
            font-size: 24px;
          }
        }
        nz-divider {
          margin: 0;
        }
      }
    }

    .uiQuoteBody {
      .uiAddButton {
        margin-top: 15px;
        color: var(--terziary-color);
        background-color: var(--neutral-color);
        border: 1px solid var(--terziary-color);
        padding: 0 50px;
        .fad {
          padding-right: 10px;
        }
      }

      .uiAddButton:hover,
      .uiAddButton:active {
        background-color: var(--neutral-color);
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        padding: 0 50px;
      }

      .uiSearchbar {
        margin-top: 15px;
      }
      .uiQtaCol {
        nz-divider {
          margin: 10px 0;
        }
        .uiLeftAdd {
          float: left;
          padding: 0 5px;
        }

        .uiRightAdd {
          float: right;
          padding: 0 5px;
        }
      }
      .uiColoredRow td {
        background-color: rgb(245, 245, 245);
        .uiOpenText {
          background-color: rgb(245, 245, 245);
        }
      }
      .uiEipsisCol {
        div {
          width: 100%;
          height: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .uiOpenText {
          position: absolute;
          bottom: 0;
          right: 0px;
          padding: 3px 3px 0 3px;
          background-color: var(--neutral-color);
        }
      }
    }
  }
  .uiQuoteSummary {
    z-index: 10;
    text-align: center;
    font-size: 20px;
    position: fixed;
    bottom: calc(70px + 37px);
    height: 70px;
    width: 100%;
    padding: 0 20px;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .uiQuoteSummaryT {
      float: right;
      margin-left: 1rem;
    }
  }

  .uiQuoteFooter {
    position: fixed;
    bottom: 37px;
    width: 100%;
    justify-content: flex-end !important;

    z-index: 1000;
    .uiBackButton {
      position: absolute;
      left: 20px;
      .fad {
        margin-left: -4px;
      }
    }

    .uiLeftButton {
      position: absolute;
      left: 20px;
      color: var(--terziary-color);
      background-color: var(--neutral-color);
      border: 1px solid var(--terziary-color);
      padding: 0 50px;
      .fad {
        padding-right: 10px;
      }
    }

    .uiRightProd {
      color: var(--terziary-color);
      background-color: var(--neutral-color);
      border: 1px solid var(--terziary-color);
      padding: 0 50px;
      text-align: right;
      margin-left: 10px;
      .fad {
        padding-left: 10px;
      }
    }

    .uiRightProd:disabled {
      color: var(--terziary-color);
      background-color: var(--neutral-color);
      border: 1px solid var(--terziary-color);
      padding: 0 50px;
      text-align: right;
      margin-left: 10px;
      opacity: 0.7;
      .fad {
        padding-left: 10px;
      }
    }

    .uiRightProd:active,
    .uiRightProd:hover,
    .uiLeftButton:hover,
    .uiLeftButton:active {
      background-color: var(--neutral-color);
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      padding: 0 50px;
    }
  }
}

.uiAdminContainer {
  display: flex;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .uiAdminSidebar {
    position: relative;
    z-index: 10;
    min-height: calc(100vh - 70px);
    background: var(--secondary-color);
    .uiSidebarLogo {
      position: relative;
      height: 64px;
      overflow: hidden;
      line-height: 64px;
      background: var(--secondary-color);
      text-align: center;
      margin-bottom: 1rem;
    }
    .uiAdminMenu {
      background: var(--secondary-color);
      font-size: 16px;
      a {
        color: var(--terziary-color) !important;
        .uiAdminIcon {
          margin-right: 1rem;
        }
      }
      a:hover {
        color: var(--primary-color) !important;
      }
      .ant-menu-item-selected {
        background: var(--neutral-color);
        a {
          color: var(--primary-color) !important;
        }
        .uiAdminIcon {
          margin-right: 1rem;
        }
      }

      .ant-menu-item-selected:after {
        border-right: 3px solid var(--primary-color);
      }

      .uiSidebarTrigger {
        color: var(--terziary-color) !important;
        cursor: pointer;
        font-size: 16px;
        bottom: 80px;
        position: absolute;
        width: 100%;
        text-align: center;
      }
      .uiSidebarTrigger:hover {
        color: var(--primary-color) !important;
      }
    }
  }
  .uiAdminInnerLayout {
    min-height: calc(100vh - 70px);
    .uiAdminContent {
      margin: 24px;
      margin-bottom: calc(24px + 37px);
      .uiAdminInnerContent {
        padding: 24px;
        background: #fff;
        height: auto;
        height: 100%;
      }
    }
    .uiAdminFooter {
      background: var(--secondary-color);
      color: var(--terziary-color) !important;
    }
  }
}
.uiUserAdminInnerLayout {
  height: 100%;
  .uiAdminAddContainer {
    background-color: var(--terziary-color);
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    margin: -24px;
    margin-bottom: 10px;
    .uiAdminLeftAddBtn {
      margin-right: 1rem;
    }
    .uiAdminAddBtn {
      font-size: 14px;
      background-color: var(--neutral-color);
      border: 1px solid var(--terziary-color);
      padding: 0 2rem;
      color: var(--terziary-color);
      i {
        margin-right: 1rem;
      }
      i::before {
        color: #fff;
      }
    }
    .uiAdminAddBtn:hover,
    .uiAdminAddBtn:active {
      background-color: var(--neutral-color);
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
    .uiAdminAddBtn[disabled] {
      opacity: 0.5;
    }
  }
  .uiAdminTable {
    overflow: hidden;
    .uiAdminTableBody {
      .uiUserIcon {
        font-size: 20px;
        cursor: pointer;
      }
      .uiAdminUserPwd {
        pointer-events: none;
        border: none;
        background-color: transparent;
      }
      .uiAdminVisibleIcon {
        cursor: pointer;
        font-size: 20px;
      }
      .uiAdminUserAction {
        cursor: pointer;
      }
      .uiUserName {
        width: 100px;
      }
      .uiSellerSelect {
        min-width: 200px;
        text-align: left;
      }
      .uiQuoteData {
        border-bottom: 1px solid rgb(240, 240, 240);
      }
      .uiQuoteDataLabel {
        margin: 0px;
        font-size: 8px;
        position: absolute;
        right: 5px;
      }
      .uiYellowRow td {
        background-color: rgba(249, 255, 181, 0.8);
      }

      .uiGreyRow td {
        background-color: rgba(240, 240, 240, 0.3);
      }

      .uiColoredRow td {
        background-color: rgb(245, 245, 245);
      }

      .uiEipsisCol {
        div {
          width: 100%;
          height: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .uiOpenText {
          position: absolute;
          bottom: 0;
          right: 0px;
          padding: 3px 6px;
        }
      }
      .selected {
        font-weight: bold;
      }
      .uiSelectableRow {
        cursor: pointer;
      }

      .uiAddOptionContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        .uiAddOption {
          width: 50%;
          margin-right: 5%;
          margin-bottom: 5px;
        }
      }
      .uiAddInputIcon {
        margin-right: 1rem;
      }
    }
    .uiAdminAddBtn[disabled] {
      opacity: 0.5;
    }
  }

  .uiCustContactTable td {
    background-color: #eeeeee;
    .uiCustContactTableHead th {
      background-color: #eee;
    }
    .uiContactRow:hover td {
      background-color: #eeeeee;
    }
  }
  .uiCustContactTable:hover td {
    background-color: #eeeeee;
  }
}
.uiAdminInnerFooter {
  background-color: var(--terziary-color);
  text-align: right;
  margin: 0 -24px -24px -24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
}

.uiPageLayout {
  .uiPageContent {
    width: 100%;
    padding: 20px 20px 120px 20px;
  }
  .uiPageFooter {
    background-color: var(--quaternary-color);
    text-align: left;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    .uiBackButton {
      background-color: var(--neutral-color);
      border: 1px solid var(--terziary-color);
      color: var(--terziary-color);
      padding: 0 50px;
      text-align: right;
      margin-left: 10px;
    }
    .uiBackButton:active,
    .uiBackButton:hover {
      background-color: var(--neutral-color);
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
  }
}
.uiHistoryContainer {
  .uiAddButton {
    margin-top: 15px;
    color: var(--terziary-color);
    background-color: var(--neutral-color);
    border: 1px solid var(--terziary-color);
    padding: 0 50px;
    margin-bottom: 1rem;
    .fad {
      padding-right: 10px;
    }
  }

  .uiAddButton:hover,
  .uiAddButton:active {
    background-color: var(--neutral-color);
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 0 50px;
  }
  .uiAdminTable {
    .uiAdminTableBody {
      tr {
        .uiDownloadQuote {
          .uiDownloadBtn {
            border: none;
            background-color: transparent;
          }
          .uiDownloadBtn:disabled {
            opacity: 0.5;
          }
        }
      }

      .uiYellowRow td {
        background-color: rgba(249, 255, 181, 0.8);
      }

      .uiGreyRow td {
        background-color: rgba(240, 240, 240, 0.3);
      }
      .uiDataSpan {
        font-size: 10px;
        font-weight: 900;
      }
      .fad {
        font-size: 20px;
      }
    }
  }
}

.uiContactModal {
  width: 95% !important;
  top: 20px;
  padding-bottom: 20px;
}

.ant-drawer-content-wrapper {
  width: 40% !important;
  .uiTreeContainer {
    .uiSearchProd {
      margin-bottom: 20px;
    }
    nz-tree {
      border: 1px solid var(--terziary-color);
      padding: 15px;
    }
  }
  .ant-drawer-footer {
    .uiBackButton {
      background-color: var(--neutral-color);
      border: 1px solid var(--terziary-color);
      padding: 0 50px;
      width: 100%;
    }
    .uiBackButton:active,
    .uiBackButton:hover {
      background-color: var(--neutral-color);
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      padding: 0 50px;
    }
    .uiAddProdBtn:disabled,
    .uiAddProdBtn[disabled] {
      background-color: var(--neutral-color);
      border: 1px solid var(--terziary-color);
      color: var(--terziary-color);
      opacity: 0.5;
    }

    .uiAddProdBtn {
      float: right;
      font-size: 16px;
      background-color: var(--neutral-color);
      border: 1px solid var(--terziary-color);
      padding: 0 2rem;
      color: var(--terziary-color);
      i {
        margin-right: 1rem;
      }
      i::before {
        color: #fff;
      }
    }
    .uiAddProdBtn:hover:enabled,
    .uiAddProdBtn:active:enabled {
      background-color: var(--neutral-color);
      border: 1px solid var(--primary-color);
      color: var(--primary-color);

      i::after {
        color: var(--primary-color);
      }
    }
  }
}

.uiAddContainer {
  padding-bottom: 190px !important;
  .uiProdDiv {
    margin-bottom: 10px;
    .uiProdRow {
      width: 100%;
      margin: 0 0 10px 0 !important;
      .uiProdCol {
        padding: 0 !important;
        .uiProdColSpan {
          font-size: 10px;
        }
        .uiInnerColDiv {
          background-color: var(--terziary-color);
          padding: 4px 0;
          width: 95%;
          color: var(--neutral-color);
          span {
            padding-left: 15px;
          }
          .uiListCheckbox {
            float: right;
            font-size: 12px;
            width: auto;
            height: auto;
            padding: 0;
            margin-right: 5px;
            margin-top: 1px;
            .ant-checkbox {
              padding: 0;
            }
          }
        }

        .uiInnerProdCol {
          .uiYellowInput {
            background-color: rgba(249, 255, 181, 0.8);
            color: rgba(0, 0, 0, 1);
          }

          .uiYellowInput:disabled,
          .uiYellowInput:disabled input {
            color: rgba(0, 0, 0, 1);
            background-color: rgba(249, 255, 181, 0.8);
          }

          .uiTotalInput,
          .uiTotalInput input {
            color: rgba(0, 0, 0, 1);
            pointer-events: none;
            cursor: not-allowed;
            background-color: #ededed;
          }
          padding: 0 5px;
          nz-input-number {
            width: 100%;
          }
          span {
            font-size: 11px;
          }
          .uiEditSelect {
            width: 100%;
          }
        }
        .uiEstText {
          font-size: 11px;
        }
        .uiInnerProdSwiperCol {
          height: 100%;
          padding: 0 5px;
        }
        .uiProdSwiper {
          height: 330px;
          swiper {
            height: 100%;
            border: 1px solid black;
            .uiSwiperSlide {
              text-align: center;
              img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
              }
            }
          }
        }
      }
    }
  }
}

.uiPartialDiv {
  width: 100%;
  float: left;
  margin: 0;
  text-align: right;
  position: fixed;
  bottom: calc(70px + 37px);
  z-index: 1000;
  background-color: var(--secondary-color);
  padding: 20px;
  span {
    color: var(--terziary-color) !important;
    padding: 10px 20px 10px 10px;
    font-size: 20px;
  }
}

.uiModalLayout {
  nz-content {
    .uiFlexDivContainer {
      display: grid;
      row-gap: 20px;
      column-gap: 20px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      .uiImgContainer {
        .uiImgAnchor {
          height: 100%;
          width: 100%;
          .uiImg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
          }
        }
        .uiSelectedIcon {
          height: 10px;
          width: 10px;

          color: var(--primary-color);
        }
      }
    }
  }
}

.uiAdminTable {
  overflow: hidden;
}

.ql-size-huge {
  font-size: 22px;
}

.ql-size-large {
  font-size: 18px;
}

.ql-size-small {
  font-size: 11px;
}
/*
.uiQuillIta .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: 'Piccolo';
}

.uiQuillIta .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: 'Normale';
}

.uiQuillIta .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: 'Grande';
}

.uiQuillIta .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: 'Enorme';
}
*/

.uiQuillIta .ql-snow .ql-picker.ql-size .ql-picker-label::before,
.uiQuillIta .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: 'Normale';
}
.uiQuillIta .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.uiQuillIta .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
  content: 'Piccolo';
}
.uiQuillIta .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.uiQuillIta .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
  content: 'Grande';
}
.uiQuillIta .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.uiQuillIta .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
  content: 'Enorme';
}

.uiFooter {
  height: auto !important;
  font-size: 11px;
  flex: 0 0;
  padding: 10px 24px;
  background-color: var(--secondary-color);
  color: var(--terziary-color);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  .ocalab {
    color: var(--terziary-color);
  }
  .ocalab:hover {
    color: #fdc300;
  }
}

//IMAGES
.ant-image-preview-operations li:nth-last-child(2),
.ant-image-preview-operations li:nth-last-child(1) {
  display: none;
}
.ant-image-preview-operations-operation {
  color: #000;
}

.ant-modal-body {
  padding-top: 45px;
}

.uiQuoteTab nz-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  color: var(--terziary-color);
}

.uiAddCustomersTab nz-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  color: var(--terziary-color);
}
.uiAddCustomersTab {
  margin-top: 20px;
}

.uiColorGroup {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin: 0;
}
.uiRosyDot,
.uiRosyDot:hover,
.uiRosyDot:active,
.uiRosyDot:focus {
  background-color: #c0c0c0 !important;
}
.uiMangoDot,
.uiMangoDot:hover,
.uiMangoDot:active,
.uiMangoDot:focus {
  background-color: #4682b4 !important;
}
.uiOrangeDot,
.uiOrangeDot:hover,
.uiOrangeDot:active,
.uiOrangeDot:focus {
  background-color: #87cefa !important;
}
.uiBeigeDot,
.uiBeigeDot:hover,
.uiBeigeDot:active,
.uiBeigeDot:focus {
  background-color: #90ee90 !important;
}
.uiMaizeDot,
.uiMaizeDot:hover,
.uiMaizeDot:active,
.uiMaizeDot:focus {
  background-color: #f5f5dc !important;
}
.uiSageDot,
.uiSageDot:hover,
.uiSageDot:active,
.uiSageDot:focus {
  background-color: #ffd700 !important;
}
.uiOceanDot,
.uiOceanDot:hover,
.uiOceanDot:active,
.uiOceanDot:focus {
  background-color: #ffa500 !important;
}
.uiSheenDot,
.uiSheenDot:hover,
.uiSheenDot:active,
.uiSheenDot:focus {
  background-color: #d2b48c !important;
}
.uiGreyDot,
.uiGreyDot:hover,
.uiGreyDot:active,
.uiGreyDot:focus {
  background-color: #ffb6c1 !important;
}
.uiBlueDot,
.uiBlueDot:hover,
.uiBlueDot:active,
.uiBlueDot:focus {
  background-color: #9370db !important;
}
.uiTransparentDot,
.uiTransparentDot:hover,
.uiTransparentDot:active,
.uiTransparentDot:focus {
  background-color: transparent;
  border: 1px solid #ccc;
}

.uiUndefinedOption {
  display: none;
}

.ant-modal-body {
  padding-top: 20px !important;
}

.w-100 {
  width: 100% !important;
}

.w-max {
  width: max-content;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.align-items-bewween {
  align-items: space-between;
}

.justify-content-between {
  justify-content: space-between;
}
